import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import ReactDOM from 'react-dom'
import Layout from '../components/layout'
import _ from 'lodash'
import { graphql, Link } from 'gatsby'

const qs = require('query-string')
const specie = new Map()
specie.set('1', 'Clinica dei piccoli animali')
specie.set('2', 'Esotici e non convenzionali')
specie.set('3', 'Clinica equina')
specie.set('4', 'Ruminanti e suini')

const contentful = require('contentful')
const client = contentful.createClient({
  space: 'f18j4npi43lu',
  environment: 'master', // defaults to 'master' if not set
  accessToken:
    '69f6ee70ba591ce7952d3fc66f60a44d0a712bfc2925f7753093315779b847d3',
})

let cacheClinici = new Map()
let cacheDiagnostici = []

class Staff extends React.Component {
  constructor(props) {
    super(props)
    let query = qs.parse(this.props.location.search)
    let default_tipologia = ''
    let default_servizio = null

    if (!!query.id) {
      default_tipologia = query.tipo
      default_servizio = query.id
    }

    this.state = {
      advance: false,
      search: false,
      searchResult: [],
      tipologia: default_tipologia,
      servizio: default_servizio,
      clinici: [],
      diagnostici: [],
      loading: false,
      static_filter: null,
    }
  }

  toggleAdv = e => {
    this.setState(function(prevState, props) {
      return { advance: !prevState.advance }
    })
  }

  changeTipologia = e => {
    let tipologia = e.target.value
    this.setState({
      diagnostici: [],
      clinici: [],
      searchResult: [],
      search: false,
    })
    if (tipologia === 'd' && this.state.diagnostici.length === 0) {
      this.searchServiziDiagnostici()
    }
    this.setState({
      tipologia: tipologia,
    })
  }

  componentDidMount() {
    let query = qs.parse(this.props.location.search)

    if (query.specie) {
      this.setState({
        static_filter: query.specie,
      })
    }
    if (this.state.servizio) {
      this.searchByService()
    }
  }

  searchByService() {
    this.setState({
      loading: true,
    })

    let resultNode = ReactDOM.findDOMNode(this.refs.resultsContainer)
    let searchParam2 = {}
    let servizio = this.state.servizio
    if (this.state.tipologia === 'c') {
      searchParam2['content_type'] = 'schedaServizio'
    } else if (this.state.tipologia === 'd') {
      searchParam2['content_type'] = 'schedaServizioDiagnostico'
    }
    searchParam2['include'] = 2
    searchParam2['sys.id'] = servizio

    client
      .getEntries(searchParam2)
      .then(response => {
        if (!!response.items[0].fields.team) {
          //console.log(response.items[0].fields.team);
          this.setState({
            searchResult: response.items[0].fields.team,
            search: true,
            loading: false,
          })
        } else if (!!response.items[0].fields.staff) {
          this.setState({
            searchResult: response.items[0].fields.staff,
            search: true,
            loading: false,
          })
        } else {
          this.setState({
            searchResult: [],
            search: true,
            loading: false,
          })
        }
        window.scrollTo(0, resultNode.offsetTop)
      })
      .catch(console.error)
  }

  search = e => {
    e.preventDefault()
    console.log('search')
    this.setState({
      loading: true,
      static_filter: null,
      searchResult: [],
    })

    //ricerca avanzata
    if (this.state.advance) {
      let searchParam2 = {}
      let servizio = this.servizioInput.value

      if (this.state.tipologia === 'c') {
        searchParam2['content_type'] = 'schedaServizio'
      } else if (this.state.tipologia === 'd') {
        searchParam2['content_type'] = 'schedaServizioDiagnostico'
      }

      searchParam2['include'] = 2
      searchParam2['sys.id'] = servizio

      client
        .getEntries(searchParam2)
        .then(response => {
          if (!!response.items[0].fields.team) {
            //console.log(response.items[0].fields.team);
            this.setState({
              searchResult: response.items[0].fields.team,
              search: true,
              loading: false,
            })
            
          } else {
            this.setState({
              searchResult: [],
              search: true,
              loading: false,
            })
          }
        })
        .catch(console.error)
    } else {
      //ricerca semplice
      let searchParam = {}
      let ruolo = this.ruoloInput.value
      let cognome = this.cognomeInput.value
      searchParam['content_type'] = 'persona'
      searchParam['include'] = 2
      //searchParam['order'] = 'fields.cognome';
      if (ruolo !== '') {
        searchParam['fields.ruolo[in]'] = ruolo
      }
      if (cognome !== '') {
        searchParam['fields.cognome[match]'] = cognome
      }

      //ricerca semplice
      client
        .getEntries(searchParam)
        .then(response => {
          this.setState({
            searchResult: response.items,
            search: true,
            loading: false,
          })
        })
        .catch(console.error)
    }
  }

  searchServiziClinici = e => {
    e.preventDefault()
    let specie = e.target.value
    let searchParam = {}
    searchParam['content_type'] = 'schedaServizio'
    searchParam['include'] = 1

    if (specie !== '') {
      searchParam['fields.specie[in]'] = specie
    }

    if (cacheClinici.has(specie)) {
      this.setState({
        clinici: cacheClinici.get(specie),
        search: false,
      })
    } else {
      client
        .getEntries(searchParam)
        .then(response => {
          cacheClinici.set(specie, response.items)
          this.setState({
            clinici: response.items,
            search: false,
          })
        })
        .catch(console.error)
    }
  }

  searchServiziDiagnostici = () => {
    let searchParam = {}
    searchParam['content_type'] = 'schedaServizioDiagnostico'
    searchParam['include'] = 1
    if (cacheDiagnostici.length > 0) {
      this.setState({
        diagnostici: cacheDiagnostici,
        search: false,
      })
    } else {
      client
        .getEntries(searchParam)
        .then(response => {
          cacheDiagnostici = response.items
          this.setState({
            diagnostici: response.items,
            search: false,
          })
        })
        .catch(console.error)
    }
  }

  render() {
    const {
      search,
      searchResult,
      advance,
      tipologia,
      clinici,
      diagnostici,
      servizio,
      loading,
      static_filter,
    } = this.state

    let alldata = this.props.data.allContentfulPersona.edges
    let data = search || servizio ? searchResult : alldata

    if (static_filter && specie.get(static_filter)) {
      let sp_filter = specie.get(static_filter)
      data = _.filter(alldata, function(o) {
        return o.node.specieAnimale && o.node.specieAnimale.includes(sp_filter)
      })
    }

    const breadcrumb = [{ title: 'Staff' }]

    return (
      <Layout location={this.props.location}>
        <div className="container">
          <Helmet title="Staff" />
          <Breadcrumbs breadcrumb={breadcrumb} />
          <div className="row">
            <div className="col-12">
              <h1>Staff</h1>
            </div>
          </div>
        </div>
        <div
          id="ricerca-sfaff"
          className="section section--dark section--m-bottom"
          role="region"
          aria-labelledby="region3"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <form
                  action="#"
                  onSubmit={this.search}
                  method="post"
                  name="ricerca-staff"
                  className="form"
                >
                  <fieldset className="form__fieldset">
                    <legend className="form__legend visuallyhidden">
                      Ricerca staff
                    </legend>
                    <div className="row">
                      <div className="col-sm-6 col-md-4 col-lg-3">
                        <div className="form__group">
                          <label htmlFor="cognome" className="form__label">
                            Cognome
                          </label>
                          <input
                            ref={cognome => (this.cognomeInput = cognome)}
                            type="text"
                            name="cognome"
                            id="cognome"
                            className="form__input"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="form__group">
                          <label htmlFor="role" className="form__label">
                            Ruolo
                          </label>
                          <div className="form__group--select">
                            <select
                              defaultValue=""
                              ref={ruolo => (this.ruoloInput = ruolo)}
                              name="role"
                              id="role"
                            >
                              <option value="">Seleziona ruolo</option>
                              <option value="Direttore">
                                Direttore generale
                              </option>
                              <option value="Direttore Sanitario">
                                Direttore sanitario
                              </option>
                              <option value="Docente">Docente</option>
                              <option value="Medico Veterinario a contratto">
                                Dottore a contratto
                              </option>
                              <option value="Ricercatore">Ricercatore</option>
                              <option value="Area Operatori">Area Operatori</option>
                              <option value="Area Collaboratori">Area Collaboratori</option>
                              <option value="Area Funzionari">Area Funzionari</option>
							  <option value="Assistente Tecnico">Assistente Tecnico</option>
                              <option value="Internship">Internship</option>
                              <option value="Assegnista">Assegnista</option>
                              <option value="Assegnista/Residency">
                                Assegnista/Residency
                              </option>
                              <option value="Assegnista/Intership">
                                Assegnista/Intership
                              </option>
                              <option value="Borsista">Borsista</option>
                              <option value="Specializzando dottorando">
                                Specializzando dottorando
                              </option>
                              <option value="Redattore Web e Amministrativo">
                                Redattore Web e Amministrativo
                              </option>
                              <option value="Redattore Web e Referente IT">
                                Redattore Web e Referente IT
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                   {/*  <div className="row">
                      <div className="col-sm-12">
                        <div id="btn-adv-search" className="form__btn-adv">
                          Ricerca avanzata
                          <button onClick={this.toggleAdv} type="button">
                            <i
                              className={
                                advance
                                  ? 'fa fa-chevron-up'
                                  : 'fa fa-chevron-down'
                              }
                              aria-hidden="true"
                            >
                              <span className="d-none">toggle search</span>
                            </i>
                          </button>
                        </div>
                      </div>
                    </div> */}
                    {!!advance && (
                      <div
                        id="adv-search"
                        className={advance ? 'form__adv open' : 'form__adv'}
                      >
                        <div className="row">
                          <div className="col-sm-6 col-md-4 col-lg-3">
                            <div className="form__group">
                              <label
                                htmlFor="tipologia"
                                className="form__label"
                              >
                                Tipologia servizio
                              </label>
                              <div className="form__group--select">
                                {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                                <select
                                  required="required"
                                  value={tipologia}
                                  onChange={this.changeTipologia}
                                  name="tipologia"
                                  id="tipologia"
                                >
                                  <option value="">Seleziona tipologia</option>
                                  <option value="c">Servizi clinici</option>
                                  <option value="d">Servizi diagnostici</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {tipologia === 'c' && (
                            <div className="col-sm-6 col-md-4 col-lg-3">
                              <div className="form__group">
                                <label htmlFor="specie" className="form__label">
                                  Specie animale
                                </label>
                                <div className="form__group--select">
                                  {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                                  <select
                                    required="required"
                                    onChange={this.searchServiziClinici}
                                    ref={specie => (this.specieInput = specie)}
                                    name="specie"
                                    id="specie"
                                    className="sumoselectstyle"
                                  >
                                    <option value="" selected="selected">
                                      Seleziona specie
                                    </option>
                                    <option value="Clinica dei piccoli animali">
                                      Clinica dei piccoli animali
                                    </option>
                                    <option value="Esotici e non convenzionali">
                                      Esotici e non convenzionali
                                    </option>
                                    <option value="Clinica equina equina">Clinica equina</option>
                                    <option value="Ruminanti e suini">
                                      Ruminanti e suini
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                          {!!diagnostici &&
                            tipologia === 'd' &&
                            diagnostici.length > 0 && (
                              <div className="col-sm-6 col-md-8 col-lg-6">
                                <div className="form__group">
                                  <label
                                    htmlFor="servizi-diagnositica"
                                    className="form__label"
                                  >
                                    Servizio
                                  </label>
                                  <div className="form__group--select">
                                    <select
                                      required="required"
                                      ref={servizio =>
                                        (this.servizioInput = servizio)
                                      }
                                      name="servizioInput"
                                      id="servizi-diagnositica"
                                    >
                                      {diagnostici.map((item, index) => {
                                        return (
                                          <option
                                            key={item.sys.id}
                                            value={item.sys.id}
                                          >
                                            {item.fields.titolo}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                          {!!clinici &&
                            tipologia === 'c' &&
                            clinici.length > 0 && (
                              <div className="col-sm-6 col-md-8 col-lg-6">
                                <div className="form__group">
                                  <label
                                    htmlFor="servizi-clinici"
                                    className="form__label"
                                  >
                                    Servizio
                                  </label>
                                  <div className="form__group--select">
                                    <select
                                      required="required"
                                      ref={servizio =>
                                        (this.servizioInput = servizio)
                                      }
                                      name="servizioInput"
                                      id="servizi-clinici"
                                    >
                                      {clinici.map((item, index) => {
                                        return (
                                          <option
                                            key={item.sys.id}
                                            value={item.sys.id}
                                          >
                                            {item.fields.titolo}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
                        <input
                          type="submit"
                          value="Cerca"
                          className="btn-link btn-link--block btn-link--red"
                        />
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container" ref="resultsContainer">
          {!loading && (
            <div className="row" id="elenco">
              <div className="col-12">
                <p>
                  <strong>
                    Sono stati trovati {data.length} risultati
                    {static_filter &&
                      specie.get(static_filter) && (
                        <span> per {specie.get(static_filter)}</span>
                      )}
                  </strong>
                </p>
              </div>
            </div>
          )}
          <div className="row">
            {loading && (
              <div className="col-sm-12 text-center loader">
                <img alt="loading" src="/images/loader.gif" />
              </div>
            )}
            {data.map((item, index) => {
              let sresult = search || servizio ? item.fields : item.node
              let thumb = '/images/staff-placeholder.png'
              if (sresult) {
                if (sresult.immagineLista && search) {
                  thumb = sresult.immagineLista.fields.file.url
                } else if (sresult.immagineLista) {
                  thumb = sresult.immagineLista.file.url
                }

                return (
                  <div key={index} className="col-6 col-md-4 col-xl-3">
                    <div className="staff-list">
                      <img
                        src={thumb}
                        loading="lazy"
                        alt={`${sresult.cognome} ${sresult.nome}`}
                        className="staff-list__img"
                      />
                      <div className="staff-list__detail">
                        <Link
                          to={'/staff/' + sresult.friendlyUrl}
                          title={'Vai alla scheda di ' + sresult.cognome}
                          className="staff-list__name"
                        >
                          {sresult.nome} {sresult.cognome}
                        </Link>
                        <div className="staff-list__role">{sresult.ruoloVisualizzato ? sresult.ruoloVisualizzato : sresult.ruolo}</div>
                      </div>
                    </div>
                  </div>
                )
              }
              return null
            })}
          </div>
          {/*
          <div className="row">
            <div className="col-12">
              <div className="pagination">
                <a href="#" title="Vai alla pagina precedente" className="pagination__link"><i className="fa fa-chevron-left" aria-hidden="true"></i></a>
                <span className="pagination__link pagination__link--active">1</span>
                <a href="#" title="Vai a pagina 2" className="pagination__link">2</a>
                <a href="#" title="Vai a pagina 3" className="pagination__link">3</a>
                <a href="#" title="Vai a pagina 4" className="pagination__link">4</a>
                <a href="#" title="Vai a pagina 5" className="pagination__link">5</a>
                <a href="#" title="Vai a pagina 6" className="pagination__link">6</a>
                <a href="#" title="Vai alla pagina successiva" className="pagination__link"><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
          */}
        </div>
      </Layout>
    )
  }
}

export default Staff

export const query = graphql`
  query StaffiQuery {
    allContentfulPersona(
      filter: { node_locale: { eq: "it-IT" } }
      sort: { fields: [cognome] }
    ) {
      edges {
        node {
          id
          nome
          cognome
          friendlyUrl
          ruolo
          ruoloVisualizzato
          immagine {
            file {
              url
            }
          }
          specieAnimale
          immagineLista {
            file {
              url
            }
          }
        }
      }
    }
  }
`
